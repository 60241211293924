
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RANGES } from './_demand.contants';
import { getDemandLevel } from './_demand.utils';

/** It is part of demand/index.vue comonent. Use index.vue with type = vertical | horizontal if you need only demand line */
@Component
export default class DemandLine extends Vue {
    @Prop({
        type: Number,
        validator(value: any): boolean {
            return value >= 0 && value <= 100;
        },
    })
    public demandNumber!: number;

    @Prop({
        type: Boolean,
        default: false,
    })
    public isHorizontal!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    titleHidden!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    browserTitle!: boolean;

    @Prop({
        type: String,
        default: null,
    })
    customTitle!: string;

    @Prop({
        type: String,
        default: 'left',
    })
    tooltipSide!: string;

    public ranges = RANGES;

    public get demandLevel(): number {
        return getDemandLevel(this.demandNumber);
    }

    get tooltipText() {
        if (this.demandNumber === undefined) {
            return undefined;
        }
        return this.customTitle ? this.customTitle : `Market Demand ${Math.round(this.demandNumber)}%`;
    }
}
