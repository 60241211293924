
import ClusterMarketsService, { ClusterMarketsServiceS } from '@/modules/cluster/cluster-markets.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import type Year from '@/modules/common/types/year.type';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import MarketsHistoryService, { MarketsHistoryServiceS } from '@/modules/common/modules/markets-history/markets-history.service';
import PopupEventsContainer from '@/modules/events/components/popup-events-container.vue';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import type Day from '@/modules/common/types/day.type';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import MarketsService, { MarketsServiceS } from '@/modules/markets/markets.service';
import Occupancy from '@/modules/common/components/ui-kit/occupancy/index.vue';
import Demand from '@/modules/common/components/ui-kit/demand/index.vue';
import MarketsDayScanBtn from '@/modules/markets/components/markets-day-scan-btn.vue';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import formatDate from '@/modules/common/filters/format-date.filter';
import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';
import MarketName from '@/modules/market/components/market-name.vue';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ClusterHotelsMarketsModel from '@/modules/cluster/models/cluster-markets.model';
import DayChanger from '../../common/components/day-changer.vue';
import SCAN_STATUS from '../constants/scan-status.constant';
import MarketsDocumentModel from '../models/markets-document.model';
import { MarketData } from '../interfaces/market-data.interface';

interface ITableData {
    hotelName: string,
    position: number,
    myHotel: boolean,
    hotelId: number,
    promoted?: boolean,
    page?: number,
    link?: string,
}

@Component({
    components: {
        ModalWrapper,
        DayChanger,
        PopupEventsContainer,
        Occupancy,
        Demand,
        MarketsDayScanBtn,
        MarketName,
    },
})
export default class MarketsCommonPopup extends Vue {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(ClusterMarketsServiceS) private clusterMarketsService!: ClusterMarketsService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(MarketsServiceS) protected marketsService!: MarketsService;
    @Inject(MarketsHistoryServiceS) protected marketsHistoryService!: MarketsHistoryService;
    @Inject(MarketsCommonServiceS) private marketsCommonService!: MarketsCommonService;

    @Prop({ required: true, type: Number })
    day!: Day;

    @Prop({ default: null, type: String })
    private source!: string;

    @Prop({ default: null, type: Object })
    private document!: MarketsDocumentModel;

    @Prop({ default: null, type: String })
    private compsetId!: string;

    @Prop({ default: null, type: Number })
    private hotelId!: number;

    @Prop({ type: [Number] })
    marketId!: number | null;

    @Prop({
        default: false,
        type: Boolean,
    })
    public allowSourcePage!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    private notHotelLevel!: boolean;

    get compset() {
        return this.compsetsService.getCompset(this.compsetId);
    }

    get skeleton() {
        return this.isLoading;
    }

    get compsetType() {
        if (!this.compset) {
            return null;
        }
        return this.compset.type;
    }

    get isDemo() {
        return this.userService.isDemo;
    }

    get isLoading() {
        if (!this.document || !this.compset) {
            return false;
        }

        if (this.compset) {
            return this.compset.loading.startDate && !this.compset.loading.finishDate;
        }

        return false;
    }

    get year(): Year {
        return this.documentFiltersService.storeState.settings.year;
    }

    get tableData(): ITableData[] {
        if (this.skeleton) {
            return [...Array(5).keys()].map(item => ({
                hotelName: 'fake hotel',
                position: 1,
                myHotel: false,
                hotelId: item,
                page: 1,
                link: 'link',
            }));
        }
        if (!this.document) return [] as ITableData[];

        const checkinDate = this.marketsCommonService.checkinDate(this.day, this.document);

        if (!checkinDate) {
            return [];
        }

        const addProtocolToLink = (marketData: MarketData) => {
            if (!marketData.link) return marketData;

            const isHaveProtocol = marketData.link.startsWith('http');

            if (!isHaveProtocol) {
                // eslint-disable-next-line no-param-reassign
                marketData.link = `https://${marketData.link}`;
            }

            return marketData;
        };

        const toMarketData = (hotelId: string) => {
            const {
                page, link, position, promoted, oldPosition,
            } = checkinDate[Number(hotelId)];

            return {
                hotelName: this.hotelsService.getHotelName(Number(hotelId)),
                position,
                myHotel: Number(hotelId) === Number(this.hotelId),
                hotelId: Number(hotelId),
                promoted,
                page,
                oldPosition,
                link,
            } as MarketData;
        };

        const tableData = Object
            .keys(checkinDate)
            .map(toMarketData)
            .map(addProtocolToLink);

        tableData.sort((a, b) => a.position - b.position);

        return tableData;
    }

    get myHotelPosition() {
        return this.tableData.findIndex(item => item.myHotel) + 1;
    }

    get numberOfHotels() {
        if (!this.hotelId) {
            return 0;
        }
        if (!this.document) {
            return 0;
        }
        return this.marketsCommonService.getNumberOfHotels(this.day, this.hotelId, this.document);
    }

    get isNoData() {
        const isNoData = this.marketsCommonService.isNoData(this.day, this.document);

        return isNoData;
    }

    get isNoVisibility() {
        if (this.isHotelPage) {
            return this.marketsService.isNA(this.day, this.source, this.hotelId);
        }

        return this.clusterMarketsService.isNA(this.day, +this.hotelId);
    }

    get isScanning() {
        if (!this.document) { return false; }
        return this.document.scanStatus === SCAN_STATUS.IN_PROGRESS;
    }

    get isScanDisabled() {
        return !this.marketsCommonService.isScanAvailable(this.day);
    }

    get isOutOfRange() {
        return this.marketsCommonService.isOutOfRange(this.document);
    }

    get formatScanDate() {
        const dateScan = this.marketsCommonService.dayUpdateDate(this.day, this.document);

        return formatDate(dateScan ? new Date(dateScan) : null);
    }

    get isAnyPromotion() {
        return !!this.tableData.find(item => (item.promoted === true || item.promoted === false));
    }

    get isHotelPage() {
        return this.$route.name!.includes('hotel');
    }

    get demandSettings() {
        const settings = {
            provider: this.source,
            compsetId: this.documentFiltersService.settings.compsetId,
            los: this.documentFiltersService.settings.los,
            pos: this.documentFiltersService.settings.pos,
        } as {
            provider: string | null;
            compsetId: string | null;
            los: number | null;
            pos: string | null;
            hotelId?: number;
        };

        if (!this.isHotelPage) {
            const clusterCompset = this.clusterService.getCompset(this.compsetId, this.hotelId);
            settings.compsetId = this.compsetId;
            settings.los = clusterCompset?.los[0] || null;
            settings.pos = clusterCompset?.mainPos || null;
            settings.hotelId = this.hotelId;
        }

        return settings;
    }

    async scan() {
        if (this.isScanning || this.isScanDisabled || !this.source) {
            return;
        }

        if (this.isHotelPage) {
            await this.marketsService.triggerScan(this.demandSettings, this.day);
        } else {
            await this.clusterMarketsService.triggerScan(this.demandSettings, this.day);
        }
    }

    myCardColor(day: number) {
        if (this.skeleton || !this.document) {
            return {};
        }

        if (this.isNoData || this.isNoVisibility) {
            return {
                'no-data': this.isNoData,
                'is-na': this.isNoVisibility,
            };
        }

        const color = this.marketsCommonService.getCardAssessment(day as Day, this.hotelId, this.document);

        return {
            high: color === ASSESSMENT_TYPES.GOOD,
            'med-high': color === ASSESSMENT_TYPES.NORMAL,
            'med-low': color === ASSESSMENT_TYPES.FAIR,
            low: color === ASSESSMENT_TYPES.BAD,
            rank: true,
        };
    }

    private openHistoryForCluster() {
        const doc = this.clusterService.getHotelData<ClusterHotelsMarketsModel>(this.hotelId);

        if (!doc || !doc.compsetMain) return;

        this.marketsHistoryService.marketsDocument = doc.compsetMain;
        this.marketsHistoryService.marketSettings = {
            provider: this.clusterService.currentMarketsProvider,
        };

        this.$router.push({
            name: `${this.$route.name!}.markets-history-popup`,
            params: {
                ...this.$route.params,
                historyDay: String(this.day),
            },
        });
    }

    openHistory() {
        if (this.notHotelLevel) {
            this.openHistoryForCluster();
            return;
        }

        const { settings, data } = this.marketsService;
        const source = this.source || settings.provider;

        // TODO Refactor
        if (settings && source && data[source]) {
            this.marketsHistoryService.setMarketsData(this.marketsService.data[source], { ...settings, provider: source });
            const marketsHistoryRoute = `${this.$route.path}/markets-history/${this.day}`.replace('//', '/');
            this.$router.push(marketsHistoryRoute);
        }
    }

    get promotionLogo() {
        if (/booking/.test(this.source)) {
            return this.marketsCommonService.getProgramLogo('booster');
        }

        if (/expedia/.test(this.source)) {
            return this.marketsCommonService.getProgramLogo('travel_ads');
        }

        return null;
    }

    get demand() {
        if (this.isHotelPage) {
            return this.marketsService.getDayDemand(this.source, this.day);
        }

        // TODO cluster implementation
        return null;
    }

    get occupancy() {
        if (this.isHotelPage) {
            return this.marketsService.getDayOccupancy(this.source, this.day);
        }

        // TODO cluster implementation
        return null;
    }
}
