
import { Component, Vue, Prop } from 'vue-property-decorator';
import DemandLine from './demand-line.vue';
import { getDemandRange } from './_demand.utils';

type DemandType = 'vertical' | 'horizontal' | 'expanded';

/** Generic component for all demand item graphs.
 * If new visualisation of demand is needed, add another type to DemandType and template for it. */
@Component({
    components: { DemandLine },
})
export default class CustomDemand extends Vue {
    @Prop({
        type: String,
        default: 'vertical',
    })
    type!: DemandType;

    @Prop({
        type: Number,
        default: 0,
    })
    value!: number;

    @Prop({
        type: Boolean,
        default: false,
    })
    titleHidden!: boolean;

    // To replace tiny-tooltip with regular browser title
    @Prop({
        type: Boolean,
        default: false,
    })
    browserTitle!: boolean;

    @Prop({
        type: String,
        default: null,
    })
    customTitle!: string;

    @Prop({
        type: String,
        default: 'left',
    })
    tooltipSide!: string;

    get parsedDemandValue() {
        if (!this.value || this.value < 0) {
            return 0;
        }

        return this.value;
    }

    get demandCaption(): string {
        const range = getDemandRange(this.parsedDemandValue);

        if (range) {
            return range.title;
        }

        return '';
    }
}
